var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row bg-white" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "c-search-box",
            [
              _c("template", { slot: "search" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-plant", {
                      attrs: { type: "search", name: "plantCd" },
                      on: { datachange: _vm.getLists },
                      model: {
                        value: _vm.searchParam.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParam, "plantCd", $$v)
                        },
                        expression: "searchParam.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: { required: "", label: "년도", type: "year" },
                      on: { datachange: _vm.getLists },
                      model: {
                        value: _vm.searchParam.year,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParam, "year", $$v)
                        },
                        expression: "searchParam.year",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-select", {
                      attrs: {
                        editable: _vm.editable,
                        type: "none",
                        comboItems: _vm.breakItems,
                        itemText: "codeName",
                        itemValue: "code",
                        name: "breakTypeCd",
                        label: "카탈로그",
                      },
                      on: { datachange: _vm.getLists },
                      model: {
                        value: _vm.searchParam.breakTypeCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParam, "breakTypeCd", $$v)
                        },
                        expression: "searchParam.breakTypeCd",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("apexchart", {
                  ref: "chart",
                  attrs: {
                    height: "350px",
                    options: _vm.chart.chartOptions,
                    series: _vm.chart.series,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("c-table", {
                ref: "table",
                attrs: {
                  title: "설비별 고장 건수",
                  tableId: "table",
                  merge: _vm.grid.merge,
                  gridHeight: "350px",
                  filtering: false,
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }